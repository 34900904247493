import React, {StrictMode, useRef, useEffect, useState} from 'react';
import ReactGA from "react-ga4";
// import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRoutes from "./Router";
import {AppContext} from './libs/contextLib';
import "./assets/styles/fonts.css";
import "./assets/styles/animate.min.css";
import "./assets/styles/layout.scss";
import "./assets/styles/font-awesome.css";
import "./assets/styles/main.scss";
import "./assets/styles/responsive.scss";
import Loader from './components/Loader';
import FloatingButton from './components/FloatingButton';
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
ReactGA.initialize('G-5J59S2V5F7');



function App() {
    
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    var viewport = document.querySelector("meta[name=viewport]");

    const [isPageLight, setIsPageLight] = useState(true);
    const [show, setShow] = useState(false);

    const [headerlogo, setHeaderLogo] = useState();

    if(windowSize.current[0] < 901){
        viewport.setAttribute('content', 'width=device-width, initial-scale=.5, maximum-scale=1.0, user-scalable=0'); 
    }

    return (      
        <>
            {/*<Loader />*/}
            <AppContext.Provider value={{
                isPageLight, setIsPageLight,
                show, setShow
            }}>
                {/* <Helmet>
                    <meta
                    httpEquiv="Content-Security-Policy"
                    content={`
                                default-src 'self';
                                script-src 'self';
                                style-src 'self' 'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' 'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' 'sha256-JlLjscSGDkmL2ocpKjHJ5kLbwmDtjYfInmFZV2cLbfU=' 'sha256-JRf3eZJIF59VkE1tlxlBPiCQXjGJ1uE88pEYh49RtTY=' 'sha256-Cgr9wUrG5Z/h/FuywjDkNoRMhPiJBVShCl07DurUDaE=';
                                img-src 'self' data: https:;
                                child-src https://www.youtube.com/ https://dialpad.telavi.com.ph/;
                                font-src 'self' data:;
                            `}
                    ></meta>
                </Helmet> */}
                <Header/>
                <main>
                    <AppRoutes reactGA={ReactGA} />   
                </main>
                {/* <CookieConsent
                    location="bottom"
                    cookieName="telaviCookie"
                    expires={150}
                    debug={true}
                    containerClasses="telavi-cookie"
                    contentClasses="telavi-cookie-content"
                    buttonClasses='btn btn-orange btn-accept'
                    buttonText="ACCEPT"
                    >
                    <img className='cookie-icon' src={cookieIcon} alt="" srcSet="" />
                    <h2 className='mb-1'>We use cookies</h2>
                    <p className='text-center'>This website uses cookies to ensure you get the best experience on our website.</p>
                </CookieConsent> */}
                <CookieConsent
                    location="bottom"
                    cookieName="telaviCookie blue"
                    expires={150}
                    containerClasses="telavi-cookie"
                    contentClasses="telavi-cookie-content"
                    buttonClasses='btn btn-blue btn-accept'
                    buttonText="ACCEPT"
                    >
                    {/* <img className='cookie-icon' src={cookieIcon_white} alt="" srcSet="" /> */}
                    {/* <h2 className='mb-1'>We use cookies</h2> */}
                    <p>This website uses <b>cookies</b> to ensure you get the best experience on our website.</p>
                </CookieConsent>
                <Footer/>
            </AppContext.Provider>
            {/*<FloatingButton />*/}
        </>
    );
}

export default App;