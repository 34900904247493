import React, {useRef, useState, useEffect} from 'react';
import { Fade } from "react-awesome-reveal";
import "../assets/styles/Contact.scss";
import tile from '../assets/images/tile-contact.png'
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet-async';
import "react-datepicker/dist/react-datepicker.css";
import referralImage from "../assets/images/leadsgen-banner.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LeadsGen(props) {

    const [loading, setLoading] = useState(true);
    const [overlayLoading, setOverlayLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        var els = document.querySelectorAll("input");
        els.forEach(item => {item.onkeydown = ()=>{item.classList.remove("error")}});
        setLoading(false);
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        
        var button = document.querySelector("input[type='submit']");
        setOverlayLoading(true);
        button.setAttribute("disabled", "disabled");
        
        const form = e.target.closest("form");
        const inputs = form.querySelectorAll("input")
        // const emailRegex = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const emailRegex = new RegExp (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const phoneRegex = new RegExp (/^09\d{9}$/);
        const numberRegex = new RegExp (/^[0-9]*$/);
        inputs.forEach((item)=>item.classList.remove("error"));

        const formData = new FormData(form);
        var validated = true;
        var errMessage = ""

        var data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        // VALIDATION
        // CHECK CONTACT
        if(!phoneRegex.test(data["contactNo"])){
            validated=false;
            addError("contactNo");
            errMessage="Invalid Number";
        }

        // CHECK EMAIL
        if(!emailRegex.test(data["email"])){
            validated=false;
            addError("email");
            errMessage="Invalid Email";
        }

        // CHECK FOR T&C
        if(document.querySelector("input[name='acceptTNC']:checked") == null){
            validated=false;
            addError("acceptTNC");
            errMessage="Please agree to Terms and Condition";
        }
        
        // CHECK FOR EMPTY
        formData.forEach((value, key) => {
            if(value==""){
                validated=false;
                errMessage="Please complete the form";
                addError(key);
            }
        });

        if(validated){
            var json = JSON.stringify(data);

            const response = await fetch(
                "https://2qxi85kv34.execute-api.ap-southeast-1.amazonaws.com/prod/leadgen-submit"
                , {
                method: 'POST',    
                headers: {
                    'Content-Type': 'application/json'
                },        
                body: json
            });
            const res = await response.json();
            
            if(res.status == "success"){
                navigate("/case-success")
            }
            else if(res.status == "error"){
                setTimeout(() => {
                    toast.info('You are already registered.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setOverlayLoading(false);
                    button.removeAttribute("disabled");
                }, 500);
            }
            else{
                setTimeout(() => {
                    toast.error('Uh oh! Something went wrong, please try again.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setOverlayLoading(false);
                    button.removeAttribute("disabled");
                }, 500);
            }
        
        }else{
            toast.error(errMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            setOverlayLoading(false);
            button.removeAttribute("disabled");
        }
    }

    function addError(key) { 
        var el = document.querySelector("input[name='"+key+"']");
        if(!el){var el = document.querySelector("textarea[name='"+key+"']");}
        el.classList.add("error")
    }

    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <Helmet>
                <title>Telavi - Contact Us</title>
        </Helmet>
        {overlayLoading ? 
            <div className="overlay-loader">
                <Loader/>
            </div>
            :""
        }
        <section className='referral-page midbanner midbanner-5050 p-0 leadsgen-1'>
            <div className="content-wrapper">
                <div className="left">
                    <div className="form-header">
                        <img src={tile} className='tile tile-second'/>
                        <div>
                            <h1>Get 7 months of Telavi service for FREE!</h1>
                            <h2>Sign up now to know more about the power of cloud technology. We want to hear from you and help you learn more about Telavi's many services and offers. Seamless calls, better CX conversion and more await!</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='referral-page midbanner midbanner-5050 p-0 leadsgen-2'>
            <div className="content-wrapper">
                <div className="left pt-0">
                    <form>
                        <input type="text" placeholder='Last Name' name='lastName'/>
                        <input type="text" placeholder='Given Name' name='givenName'/>
                        <input type="number" placeholder='Contact Number (09XX-YYY-ZZZZ)' name='contactNo'/>
                        <input type="email" placeholder='Email Address' name='email'/>
                        <textarea name="message" id="message" placeholder='Message Inquiry'></textarea>
                        <div className="bottom">
                            <div className="check-box">
                                <label className="check-container">
                                    <input type="checkbox" name='acceptTNC'/>
                                    <span className="checkmark"></span>
                                    <span>Agreed &nbsp;<a href="/referral-agreement" target='_blank'>Terms and Conditions</a></span>
                                </label>
                            </div>
                            <input type="submit" value="Submit"  className='btn btn-black' onClick={(e)=>{handleSubmit(e)}}/>
                        </div>
                    </form>
                </div>
                <div className="right">
                    <div className="midbanner-img">
                        <img src={referralImage} className=''/>
                    </div>
                </div>
            </div>
        </section>
        <ToastContainer/>
        </>
        </>
    );
}